<template>
  <a-spin tip="加载中" :spinning="loading">
    <div class="tableList">
      <div class="search" v-if="!modal">
        <div class="search-body" style="height: 50px">
          <a-button style="float: right" @click="gotoList" type="default"
            >公用票据整理单列表</a-button
          >
        </div>
      </div>
    </div>
    <div class="tableList">
      <div :style="{ padding: '10px', margin: '0px auto' }">
        <a-form-model
          ref="publicbill"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <template>
            <a-row>
              <a-col
                :span="24"
                style="
                  font-size: 32px;
                  text-align: center;
                  text-decoration: underline;
                "
              >
                票 据 整 理 单
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="13">
                <a-form-model-item
                  style="margin-bottom: 1px !important"
                  label="单位"
                  prop="org"
                >
                  <a-input v-model="form.orgName" readOnly />
                </a-form-model-item>
              </a-col>
              <a-col :span="5">
                <a-form-model-item
                  label="日期"
                  prop="time"
                  style="margin-bottom: 1px !important"
                >
                  <a-date-picker v-model="form.time" />
                </a-form-model-item>
              </a-col>
              <a-col :span="5">
                <a-form-model-item
                  style="margin-bottom: 1px !important"
                  label="编号"
                  prop="number"
                >
                  <a-input v-model="form.number" />
                </a-form-model-item>
              </a-col>
            </a-row>
            <table style="width: 100%" class="formtable">
              <tr>
                <td style="text-align: left" colspan="5" rowspan="3">
                  <a-form-model-item
                    :label-col="{ span: 3 }"
                    label="内容"
                    prop="content"
                    style="margin-bottom: 0px"
                  >
                    <a-input v-model="form.content" />
                  </a-form-model-item>
                  <a-form-model-item
                    :label-col="{ span: 3 }"
                    label="金额(大写)"
                    style="margin-bottom: 0px"
                    prop="price"
                  >
                    <a-input
                      placeholder="请输入金额"
                      style="width: 20%; float: right; margin-top: 3px"
                      @change="changePrice"
                      v-model="form.price"
                    />
                    <a-input
                      readOnly
                      v-model="form.amount"
                      style="width: 80%"
                    />
                  </a-form-model-item>
                </td>
                <td>付款方式</td>
                <td style="width: 150px">出纳签字</td>
              </tr>
              <tr>
                <td>银行付款</td>
                <td></td>
              </tr>
              <tr>
                <td>现金付款</td>
                <td></td>
              </tr>
              <tr>
                <td rowspan="3" style="width: 100px">单位审批</td>
                <td rowspan="1" style="text-align: left">
                  财务：
                  <auditPrint
                    :infoData="form"
                    taskPrintKey="CaiWu"
                    @callback="auditCb"
                  />
                </td>
                <td rowspan="1" style="text-align: left">
                  办公室财务副主任：
                  <auditPrint
                    :infoData="form"
                    taskPrintKey="BanGongShiFuZhuRen"
                    @callback="auditCb"
                  />
                </td>
                <td rowspan="3" style="width: 110px">核算中心</td>
                <td rowspan="3" style="text-align: left">
                  票据审核：<br /><br /><br />
                  会计主管：<br /><br /><br />
                  核算中心领导：
                </td>
                <td rowspan="2">
                  <a-row>
                    <a-col
                      :span="24"
                      style="padding-bottom: 15px; font-size: 20px"
                      >结算借款</a-col
                    >
                  </a-row>
                  <a-row>
                    <a-col :span="24" style="text-align: left">
                      <a-form-model-item
                        :label-col="{ span: 10 }"
                        :wrapper-col="{ span: 13 }"
                        label="原借款"
                        style="margin-bottom: 0px"
                      >
                        <div style="text-align: right">元</div>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="24" style="text-align: left">
                      <a-form-model-item
                        :label-col="{ span: 10 }"
                        :wrapper-col="{ span: 13 }"
                        label="结算后余额"
                        style="margin-bottom: 0px"
                      >
                        <div style="text-align: right">元</div>
                      </a-form-model-item>
                    </a-col>
                  </a-row>
                </td>
              </tr>

              <tr>
                <td style="text-align: left">
                  办公室主任：
                  <auditPrint
                    :infoData="form"
                    taskPrintKey="BanGongShiZhuRen"
                    @callback="auditCb"
                  />
                </td>
                <td style="text-align: left">
                  分管财务院领导：
                  <auditPrint
                    :infoData="form"
                    taskPrintKey="FenGuanLingDao"
                    @callback="auditCb"
                  />
                </td>
              </tr>
              <tr>
                <td style="text-align: left" colspan="2">
                  院长：
                  <auditPrint
                    :infoData="form"
                    taskPrintKey="YuanChang"
                    @callback="auditCb"
                  />
                </td>
                <td colspan="2" style="text-align: left">
                  <a-form-model-item
                    label="业务经办人"
                    prop="handler"
                    style="margin-bottom: 0px"
                  >
                    <a-input v-model="form.handlerName" readOnly />
                  </a-form-model-item>
                </td>
              </tr>
              <tr>
                <td>附件</td>
                <td colspan="7" style="text-align: left">
                  <a-form-model-item
                    label=""
                    STYLE="margin-bottom:1px !important;"
                  >
                    附件张数：<a-input
                      style="width: 120px; padding: 0px; text-align: center"
                      v-model="form.fileCount"
                    />
                    <a-upload
                      :action="uploadFileUrl"
                      :fileList="fileList"
                      @change="handleFileChange"
                      @preview="showWpsRead"
                      :remove="upLoadRemove"
                    >
                      <div v-if="form.status == 0 || form.status == 3">
                        <a-button><a-icon type="upload" />上传文件</a-button>
                      </div>
                    </a-upload>
                  </a-form-model-item>
                </td>
              </tr>
            </table>
            <hr />
          </template>

          <a-row style="text-align: center; margin-top: 20px">
            <a-col>
              <a-button
                type="primary"
                v-if="form.processExecuteStatus"
                style="margin-right: 10px"
                @click="showaudit()"
                >审批</a-button
              >
              <a-button
                type="primary"
                v-if="form.status == 0 || form.status == 3"
                style="margin-right: 10px"
                @click="handleSubmit(0)"
                >保存</a-button
              >
              <a-popconfirm
                v-if="form.status == 0 || form.status == 3"
                title="提交后数据不可更改，是否确认？"
                @confirm="handleSubmit(1)"
              >
                <a-button type="danger" style="margin-right: 10px"
                  >提交</a-button
                >
              </a-popconfirm>
              <a-button
                v-if="form.status == 2"
                @click="showWps"
                style="margin-right: 10px"
                >打印</a-button
              >
              <a-button @click="cancelClick">取消</a-button>
            </a-col>
          </a-row>
        </a-form-model>
        <a-modal
          title="选择用户"
          v-model="selectuseModal.visible"
          width="70%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <selectuser @callback="selectuserCallback" />
        </a-modal>
        <a-modal
          title="审批"
          v-model="auditModal.visible"
          width="40%"
          :footer="false"
          destroyOnClose
          :maskClosable="false"
        >
          <audit
            :info="auditModal.info"
            :formValidPass="formValidPass"
            @callback="auditCallBack"
          />
        </a-modal>
        <a-modal
          :visible="showImgModal"
          :footer="false"
          width="80%"
          destroyOnClose
          :maskClosable="false"
          @cancel="showImghandleCancel"
        >
          <iframe
            :src="imgurl"
            frameborder="0"
            width="100%"
            height="800px"
          ></iframe>
        </a-modal>
      </div>
    </div>
  </a-spin>
</template>
<script>
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import publicbillApi from "@/api/work/publicbill";
import request from "@/request";
import pinyin from "js-pinyin";

export default {
  name: "publicbill",
  data() {
    return {
      imgurl: "",
      showImgModal: false,
      uploadFileUrl: "",
      fileList: [],
      form: {
        status: 0,
        amount: "",
      },

      labelCol: { span: 6 },
      wrapperCol: { span: 17 },
      orgs: [],
      loading: false,
      selectuseModal: {
        visible: false,
        record: null,
      },
      auditModal: {
        info: null,
        visible: false,
      },
      rules: {
        time: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
        number: [
          {
            required: false,
            message: "请输入编号",
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: "请输入内容",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请输入金额",
            trigger: "blur",
          },
        ],
      },
      formValidPass: true,
      currUser: localStorage.getItem(this.$commons.User.userName),
    };
  },
  props: {
    info: {
      type: Object,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: { selectuser },
  mounted() {
    this.getData();
    this.uploadFileUrl = request.getUploadUrl("document");
  },

  methods: {
    changePrice(e) {
      let newVal = e.target.value.replace(/[^0-9.]/g, "");
      this.$set(this.form, "price", newVal);
      if (newVal) {
        this.form.amount = this.$util.menoyToUppercase(newVal);
      }
    },
    gotoList() {
      this.$router.push({ path: "/business/publicbilllist" });
    },
    getfileList(v) {
      return "fileList" + v;
    },
    getPostData() {
      let formData = { ...this.form };
      if (this.info) {
        formData.id = this.info.id;
      }
      delete formData.createTime;
      delete formData.creator;
      delete formData.creatorName;
      delete formData.status;
      delete formData.auditRecords;
      delete formData.currentNode;
      delete formData.currentNodeName;
      delete formData.handler;
      delete formData.handlerName;
      delete formData.processExecuteStatus;
      delete formData.processId;
      delete formData.processStatus;
      delete formData.processModelKey;
      delete formData.reportTime;
      delete formData.taskId;
      delete formData.taskKey;
      delete formData.taskRoles;
      delete formData.taskUsers;
      delete formData.xiangmu;

      if (formData.items) {
        formData.items.forEach((item) => {
          if (item.time) {
            item.id = item.id;
            item.specialExpenditureItemId = Number(
              item.specialExpenditureItemId
            );
            delete item.creator;
            delete item.creatorName;
            delete item.orgId;
            delete item.orgName;
            delete item.handler;
            delete item.handlerName;
          }
        });
      }
      return formData;
    },
    showImghandleCancel() {
      this.showImgModal = false;
    },
    showWpsRead(file) {
      let type = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (
        type.toLowerCase().indexOf("doc") <= -1 &&
        type.toLowerCase().indexOf("xls") <= -1 &&
        type.toLowerCase().indexOf("ppt") <= -1 &&
        type.toLowerCase().indexOf("pdf") <= -1
      ) {
        request
          .isImageUrlValid(file.response.url)
          .then(() => {
            this.imgurl = file.response.url;
            this.showImgModal = true;
          })
          .catch(() => {
            window.open(file.response.url, "_blank");
          });
      } else {
        let fileId =
          "file" +
          file.response.url
            .substring(
              file.response.url.lastIndexOf("/") + 1,
              file.response.url.lastIndexOf(".")
            )
            .replaceAll("-", "");
        let url = file.response.url.substring(
          file.response.url.indexOf("oabucket")
        );
        let type = file.response.url.substring(
          file.response.url.lastIndexOf(".") + 1
        );
        localStorage.removeItem("fileRrl");
        localStorage.setItem("fileRrl", url);
        localStorage.removeItem("fileName");
        localStorage.setItem("fileName", file.name);
        localStorage.removeItem("fileId");
        localStorage.setItem("fileId", fileId);
        localStorage.removeItem("fileTpye");
        localStorage.setItem("fileTpye", type);
        const jump = this.$router.resolve({
          name: "wpsRead",
          query: {
            //要传的参数
            fileName: file.name,
          },
        });

        window.open(jump.href, "_blank");
      }
    },
    async showaudit() {
      let validRes = await this.$refs.publicbill.validate();
      if (!validRes) {
        this.$message.error("请填写必填项");
        return;
      }
      this.formValidPass = validRes;
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    async auditCallBack(data) {
      let close = true;
      this.auditModal.visible = false;
      if (data != null && data.updateModel) {
        close = false;
        let validRes = await this.$refs.publicbill.validate();
        if (!validRes) {
          return false;
        }
        if (this.fileList.length == 0) {
          this.$message.error("请上传附件");
          return false;
        }
        var file = [];
        this.fileList.forEach((f) => {
          file.push({
            title: f.response.title,
            url: f.response.url,
          });
        });
        this.loading = true;
        this.loadingTip = "保存中...";
        let formData = {
          id: this.info.id,
          time: this.form.time,
          content: this.form.content,
          price: this.form.price,
          amount: this.form.amount,
          fileCount: this.form.fileCount,
          kemu: this.form.kemu,
          itemAmount: this.form.itemAmount,
          files: JSON.stringify(file),
          updateOnly: true,
        };
        let res = await publicbillApi.addOrUpdate(formData);
        if (res.errorCode == "0000") {
          this.loading = false;
          close = true;
        }
      }
      if (close) {
        this.$emit("callback", data != null);
      }
    },
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch(type) {
      this.selectuseModal.visible = true;
      this.selectuseModal.record = type;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgDates = res.data.tree[0].children;
          res.data.tree[0].orgsNumber = this.orgsNumber;
          this.orgsNumber = this.orgsNumber++;
          this.orgs = res.data.tree;
          // if(!this.data){
          //   this.form.type=res.data.path;
          this.form.orgId = res.data.path;
          let tree = this.orgs;
          for (var i = 0; i < res.data.path.length; ++i) {
            var id = res.data.path[i];
            var find = tree.filter((n) => n.id == id);
            if (i == res.data.path.length - 1) {
              this.form.type = find[0].name;
              this.form.orgId = find[0].id;
              this.form.orgName = "河北省教育科学研究院/" + find[0].name;
            } else {
              tree = find[0].children;
            }
          }
          // }
          this.form.handlerName = res.data.realName;
          this.form.handler = localStorage.getItem(this.$commons.User.userName);

          this.$forceUpdate();
        }
      });
    },
    getData() {
      if (this.info) {
        let _this = this;
        publicbillApi.getData(this.info.id).then((res) => {
          if (res.errorCode == this.$commons.RespCode.fail) {
          } else if ((res.errorCode = this.$commons.RespCode.success)) {
            this.form = res.data;
            if (this.form.files) {
              var d = JSON.parse(this.form.files);
              var files = [];
              for (var i = 1; i <= d.length; ++i) {
                var a = d[i - 1];
                files.push({
                  uid: i,
                  name: a.title,
                  status: "done",
                  response: a,
                  url: a.url,
                });
              }
              this.fileList = files;
            } else {
              this.fileList = [];
            }
          }
        });
      } else {
        this.getOrgTree();
        this.form.time = this.$moment();
      }
    },
    upLoadRemove() {
      return this.form.status == 0 || this.form.status == 3;
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "uploading") {
      }
      if (fileInfo.file.status == "done") {
      }
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },
    selectuserCallback(user) {
      this.form[this.selectuseModal.record] = user.userName;
      this.form[this.selectuseModal.record + "Name"] = user.realName;
      this.selectuseModal.visible = false;
    },
    handleSubmit(v) {
      if (this.fileList.length == 0) {
        this.$message.error("请上传附件");
        return false;
      }
      var file = [];
      this.fileList.forEach((f) => {
        file.push({
          title: f.response.title,
          url: f.response.url,
        });
      });
      this.form.files = JSON.stringify(file);
      let formData = { ...this.form, status: v };
      delete formData.auditRecords;
      publicbillApi.addOrUpdate(formData).then((res) => {
        if (this.modal) {
          this.$emit("callback", true);
        } else {
          this.gotoList();
        }
      });
    },
    update() {
      let data = this.getPostData();
      data.items.forEach((e) => {
        if (e.fileList.length > 0) {
          var file = [];
          e.fileList.forEach((f) => {
            file.push({
              title: f.response.title,
              url: f.response.url,
            });
          });
          e.files = JSON.stringify(file);
        }
        delete e.fileList;
      });
      publicbillApi.update(data).then((res) => {
        this.getData();
      });
    },
    cancelClick() {
      this.$emit("callback");
    },
    auditCb(data) {
      this.$emit("callback",data!=null);
      this.getData();
    },
    showWps() {
      let templateId = "publicbill";
      let innerData = Object.assign({}, this.form);
      delete innerData.auditRecords;
      delete innerData.processExecuteStatus;
      delete innerData.processId;
      delete innerData.processModelKey;
      delete innerData.processStatus;
      delete innerData.taskId;
      delete innerData.taskKey;
      delete innerData.taskRoles;
      delete innerData.taskUsers;
      delete innerData.status;
      innerData.time = this.$moment(innerData.time, "YYYY-MM-DD").format(
        "YYYY年MM月DD日"
      );
      innerData.price = innerData.price ? innerData.price.toFixed(2) : "0.00";
      if (innerData.orgName.indexOf("/") > 0) {
        innerData.orgName = innerData.orgName.substring(
          0,
          innerData.orgName.indexOf("/")
        );
      }
      if (innerData.handlerPic != null && innerData.handlerPic != "") {
        innerData.handlerPic =
          "http://localhost:9000/" +
          innerData.handlerPic.substring(innerData.handlerPic.indexOf("uas"));
      }
      this.form.auditRecords.forEach((a) => {
        let key =
          "taskKey" + pinyin.getFullChars(a.taskKey.replace(/\d+/g, ""));
        if (a.taskPrintKey != null) {
          key = "taskKey" + a.taskPrintKey;
        }
        if (innerData[key] == null) {
          innerData[key] = [];
        }
        let auditPic = "";
        if (a.auditUserSignPicture != null && a.auditUserSignPicture != "") {
          let s = a.auditUserSignPicture.indexOf("uas");
          auditPic =
            "http://localhost:9000/" +
            a.auditUserSignPicture.substring(
              a.auditUserSignPicture.indexOf("uas")
            );
        }
        let task = {
          auditRemark: a.auditRemark,
          auditPic: auditPic,
          auditName: a.auditUserName,
          auditTime: this.$moment(a.time, "YYYY-MM-DD").format(
            "YYYY年MM月DD日"
          ),
        };
        innerData[key].push(task);
      });
      localStorage.setItem("innerData", JSON.stringify(innerData));
      localStorage.removeItem("publicFileId");
      const jump = this.$router.resolve({
        name: "wpsedit",
        query: {
          //要传的参数
          templateId: templateId,
          fileType: "public",
          openType: "create",
          fileName: "公用票据整理单",
          tempUrl: "writeNumberTemp",
        },
      });
      window.open(jump.href, "_blank");
    },
  },
};
</script>
